@font-face {
  font-family: 'Grosa Trial';
  src: url('/fonts/GrosaTrial/GrosaTrial-Light.otf');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'Grosa Trial';
  src: url('/fonts/GrosaTrial/GrosaTrial-Regular.otf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Grosa Trial';
  src: url('/fonts/GrosaTrial/GrosaTrial-Medium.otf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Grosa Trial';
  src: url('/fonts/GrosaTrial/GrosaTrial-MediumItalic.otf');
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Nantes Trial Book';
  src: url('/fonts/NantesTrial/NantesTrial-SemiBold.otf');
  font-display: swap;
}
@font-face {
  font-family: 'Nantes Trial Book';
  src: url('/fonts/NantesTrial/NantesTrial-BookItalic.otf');
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Nantes Trial Light';
  src: url('/fonts/NantesTrial/NantesTrial-LightItalic.otf');
  font-display: swap;
}
